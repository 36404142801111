<template>
    <fragment>
        <div class="b1">
            <div class="page-title dashboard">
                <div class="container">
                    <div class="row">
                        <div class="col-6">
                            <div class="page-title-content">
                                <p>{{ $t('wallet.title1') }}
                                    <span> {{ user.username }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-6">
                            <ul class="text-right breadcrumbs list-unstyle">
                                <li><a href="/profile/wallet">{{ $t('settings.link1') }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="row">
                                <!-- <div class="col-xl-6 col-md-6">
                                    <Verification />
                                </div> -->
                                <div class="col-xl-6 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <form data-vv-scope="form-psw">
                                                <div class="form-row">
                                                    <div class="form-group col-xl-12">
                                                        <label class="mr-sm-2">{{ $t('settings.text1') }}</label>
                                                        <input id="phone_number" type="email" class="form-control_blocked"
                                                            :placeholder="user.phone" value="" disabled>
                                                    </div>

                                                    <div class="form-group col-xl-12">
                                                        <label class="mr-sm-2">{{ $t('settings.text2') }}</label>
                                                        <input id="old_password" type="password" class="form-control"
                                                            placeholder="**********" v-model="password.old" name="password"
                                                            v-validate="'required'">

                                                    </div>
                                                    <div class="form-group col-xl-12">
                                                        <label class="mr-sm-2">{{ $t('settings.text3') }}</label>
                                                        <input id="new_password" type="password" class="form-control"
                                                            placeholder="**********" v-model="password.new" name="password"
                                                            v-validate="{ required: true, min: 8, max: 40, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }">

                                                    </div>

                                                    <div class="col-12">
                                                        <button id="user_profile_save" type="button" @click="newPassword()"
                                                            class="btn btn-success waves-effect px-4">{{ $t('settings.btn1')
                                                            }}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">{{ $t('settings.title1') }}</h4>
                                        </div>
                                        <div class="card-body">
                                            <form class="personal_validate" data-vv-scope="form-upd">
                                                <div class="form-row">
                                                    <div class="form-group col-xl-6 col-md-6">
                                                        <label class="mr-sm-2">{{ $t('settings.text4') }}</label>
                                                        <input v-model="user.name" type="text" class="form-control"
                                                            :placeholder="$t('settings.text5')"
                                                            v-validate="{ required: true, regex: /^[a-zA-Z\s]+$/ }"
                                                            name="name">
                                                    </div>
                                                    <div class="form-group col-xl-6 col-md-6">
                                                        <label class="mr-sm-2">{{ $t('settings.text6') }}</label>
                                                        <input type="email" class="form-control_blocked"
                                                            :placeholder="user.email" disabled>
                                                    </div>
                                                    <div class="form-group col-xl-6 col-md-6">
                                                        <label class="mr-sm-2">{{ $t('settings.text7') }}</label>
                                                        <input v-model="user.dob" type="text" class="form-control"
                                                            placeholder="31/12/2006" autocomplete="off"
                                                            v-validate="'required|date_format:dd/MM/yyyy|date_between:01/01/1940,31/12/2006'"
                                                            name="dob">
                                                    </div>
                                                    <div class="form-group col-xl-6 col-md-6">
                                                        <label class="mr-sm-2">{{ $t('settings.text8') }}</label>
                                                        <input v-model="user.address.present" type="text"
                                                            class="form-control" placeholder="56, Old Street, Brooklyn"
                                                            v-validate="'required'" name="address">
                                                    </div>
                                                    <div class="form-group col-xl-6 col-md-6">
                                                        <label class="mr-sm-2">{{ $t('settings.text9') }}</label>
                                                        <input v-model="user.address.permanent" type="text"
                                                            class="form-control" placeholder="123, Central Square, Brooklyn"
                                                            v-validate="'required'" name="address">
                                                    </div>
                                                    <div class="form-group col-xl-6 col-md-6">
                                                        <label class="mr-sm-2">{{ $t('settings.text12') }}</label>
                                                        <input v-model="user.address.city" type="text" class="form-control"
                                                            placeholder="New York"
                                                            v-validate="{ required: true, regex: /^[a-zA-Z\s]+$/ }"
                                                            name="city">
                                                    </div>
                                                    <div class="form-group col-xl-6 col-md-6">
                                                        <label class="mr-sm-2">{{ $t('settings.text10') }}</label>
                                                        <input v-model="user.address.zip" type="text" class="form-control"
                                                            placeholder="25481" v-validate="'required'" name="zip">
                                                    </div>
                                                    <div class="form-group col-xl-6 col-md-6">
                                                        <label class="mr-sm-2">{{ $t('settings.text11') }}</label>
                                                        <div class="nice-select form-control"
                                                            :class="{ open: active.option }"
                                                            @click="active.option = !active.option"
                                                            v-click-outside="() => active.option = false" tabindex="0">
                                                            <span class="current">{{ user.address.country || 'Select'
                                                            }}</span>
                                                            <ul class="list">
                                                                <li data-value="" class="option"
                                                                    :class="{ selected: user.address.country == item }"
                                                                    @click="user.address.country = null">Select</li>
                                                                <li v-for="item in countries" :key="item" data-value="item"
                                                                    class="option"
                                                                    :class="{ selected: user.address.country == item }"
                                                                    @click="user.address.country = item">{{ item }}</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="form-group col-12">
                                                        <button id="update_person_info" type="button"
                                                            class="btn btn-success px-4" @click="updateUser()">{{
                                                                $t('settings.btn1') }}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fragment>
</template>

<script>
// import Verification from '../../components/profile/Verification'
import vClickOutside from 'v-click-outside'
import CONFIG from '../../config/settings'

export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            password: {
                old: null,
                new: null,
            },
            active: { option: false },
            countries: [
                'Austria',
                'Azerbaijan',
                'Belarus',
                'Belgium',
                'Bulgaria',
                'Canada',
                'China',
                'Czech Republic',
                'Denmark',
                'Egypt',
                'Estonia',
                'Finland',
                'France',
                'Georgia',
                'Germany',
                'Greece',
                'Hungary',
                'India',
                'Italy',
                'Kazakhstan',
                'Latvia',
                'Netherlands',
                'Nigeria',
                'Norway',
                'Poland',
                'Romania',
                'Russian Federation',
                'Slovakia',
                'Slovenia',
                'South Africa',
                'Spain',
                'Sweden',
                'Switzerland',
                'Turkey',
                'United Arab Emirates',
                'United Kingdom',
                'United States',
                'Uzbekistan',
            ]
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn
        },
        user() {
            return this.$store.state.auth.user
        },
    },
    created() {
        if (!this.loggedIn)
            window.location.replace(CONFIG.BASEURL + '/signin')
    },
    methods: {
        updateUser() {
            this.$validator.validate('form-upd.*').then(isValid => {
                if (isValid) {
                    if (!this.user.address.country) return this.$toast.warning('Country field is required')
                    this.$store.dispatch('auth/updateProfile', this.user).then(
                        () => {
                            this.password.old = null
                            this.password.new = null
                            this.$toast.success('User data successfully saved')
                        },
                        error => {
                            this.$toast.error(error.response.data)
                        }
                    )
                } else {
                    let name = this.errors.has('form-upd.name')
                    let dob = this.errors.has('form-upd.dob')
                    let address = this.errors.has('form-upd.address')
                    let zip = this.errors.has('form-upd.zip')
                    let city = this.errors.has('form-upd.city')
                    if (name)
                        this.warning('form-upd.name')
                    else if (!name && dob)
                        this.warning('form-upd.dob')
                    else if (!name && !dob && address)
                        this.warning('form-upd.address')
                    else if (!name && !dob && !address && zip)
                        this.warning('form-upd.zip')
                    else if (!name && !dob && !address && !zip && city)
                        this.warning('form-upd.city')
                }
            })
        },
        newPassword() {
            this.$validator.validate('form-psw.*').then(isValid => {
                if (isValid) {
                    this.$store.dispatch('auth/updatePassword', { password: { old: this.password.old, new: this.password.new } }).then(
                        () => {
                            this.password.old = null
                            this.password.new = null
                            this.$toast.success('Password updated')
                        },
                        error => {
                            this.$toast.error(error.response.data)
                        }
                    )
                } else {
                    this.warning('form-psw.password')
                }
            })
        },
        warning(value) {
            if (this.errors.has(value))
                this.$toast.warning(this.errors.first(value))
        },
    },
    // components: {
    //     Verification
    // }
}
</script>

<style>
.image-form-settings img {
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
</style>

<!-- The picture format does not meet our requirements -->

